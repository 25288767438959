<template>
  <div class="EduHome">
    <EduHeader />
    <main class="containerMain">
      <NavInfo />
      <div class="containerMainRight" style="padding-left: 60px">
        <div class="ExaminationCenter">
          <div class="ExaminationCenterTitle">题库</div>
          <div v-if="examCategoryData.length > 0" class="ExaminationCenterUl">
            <ul>
              <li
                v-for="(
                  examCategoryItem, examCategoryIndex
                ) in examCategoryData"
                :key="examCategoryIndex"
              >
                <div class="ExaminationCenterUl-img">
                  <img
                    v-if="examCategoryItem.coverImage"
                    :src="examCategoryItem.coverImage"
                  />
                  <img v-else src="~@/assets/Examination/img-1.jpg" />
                  <!--~@/assets/Examination/img-1.jpg-->
                </div>
                <div class="ExaminationCenterUl-div">
                  <div class="ExaminationCenterUl-title">
                    {{ examCategoryItem.name }}
                  </div>
                  <div class="ExaminationCenterUl-but">
                    <div class="but1" @click="goToExamList(examCategoryItem)">
                      查看
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <el-empty v-else description="暂无数据"></el-empty>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import EduHeader from "@/components/EduHeader";
import NavInfo from "@/components/NavInfo";
import { getExamCategoryList } from "@/api/examCategoryController";
export default {
  name: "EduHome",
  data() {
    return {
      activeName: "Teaching",
      show: false,
      info: "对",
      examCategoryData: [],
      queryForm: {
        __page: 0,
        __pageSize: 100,
        __orderBy: "idx",
        published: true,
        courseId: null,
      },
      payIndex: 0,
      scanningCodeUrl: "",
      orderStatusTimer: -1,
      fileShow: false,
      examId: -1,
      courseId: -1,
    };
  },
  created() {
    this.queryForm.courseId = this.getCourseId(); //this.$route.params && this.$route.params.id;
    this.getExamList();
  },
  beforeDestroy() {
    window.clearTimeout(this.orderStatusTimer);
  },
  methods: {
    getCourseId() {
      let id = null; //sessionStorage.getItem("COURSE_ID");
      const key = "COURSE_ID";
      if (this.$cookies.isKey(key)) {
        id = this.$cookies.get(key);
      }
      return id;
    },
    getExamList() {
      getExamCategoryList(this.queryForm).then((res) => {
        if (res.code == 20000) {
          this.examCategoryData = res.data;
        } else {
          this.$baseMessage(res.data.msg, "error", "vab-hey-message-error");
        }
      });
    },
    goToExamList(item) {
      this.$router.push({
        path: "/Examination/" + item.id,
      });
    },
    showBu(row) {
      this.info = row;
      this.show = false;
    },
    showFn() {
      this.show = !this.show;
    },
  },
  components: {
    EduHeader,
    NavInfo,
  },
};
</script>

<style lang="scss" scoped>
.containerMain {
  background: #fafafa;
  position: relative;
  .containerMainRight {
    padding-left: 410px;
    .ExaminationCenter {
      width: 1200px;
      margin: 0px auto;
      padding: 75px 0 15px 0;
      .ExaminationCenterTitle {
        font-size: 36px;
        font-weight: 400;
        color: #060606;
        line-height: 60px;
        margin-top: 10px;
      }
      .ExaminationCenterUl {
        ul {
          margin: 0 -15px;
          li {
            cursor: pointer;
            margin: 0 15px;
            float: left;
            width: 274px;
            height: 281px;
            background: #ffffff;
            box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
            border-radius: 6px;
            border: 1px solid #707070;
            margin-top: 25px;
            .ExaminationCenterUl-img {
              font-size: 0;
              img {
                width: 100%;
                height: 135px;
              }
            }
            .ExaminationCenterUl-div {
              padding: 20px 5px;
              text-align: center;
              .ExaminationCenterUl-title {
                font-size: 16px;
                font-weight: 400;
                color: #173d7c;
                line-height: 36px;
              }
              .ExaminationCenterUl-but {
                text-align: center;
                padding-top: 20px;
                .but1 {
                  width: 120px;
                  height: 50px;
                  background: #173d7c;
                  border-radius: 10px;
                  display: inline-block;
                  line-height: 50px;
                  font-size: 16px;
                  font-weight: 400;
                  color: #ffffff;
                  cursor: pointer;
                  margin: 0 5px;
                }
                .but2 {
                  width: 110px;
                  height: 50px;
                  background: #434343;
                  border-radius: 10px;
                  display: inline-block;
                  line-height: 50px;
                  font-size: 16px;
                  font-weight: 400;
                  color: #ffffff;
                  cursor: pointer;
                  margin: 0 5px;
                }
                .but3 {
                  width: 55px;
                  height: 50px;
                  background: #434343;
                  border-radius: 10px;
                  display: inline-block;
                  line-height: 50px;
                  font-size: 16px;
                  font-weight: 400;
                  color: #ffffff;
                  cursor: pointer;
                  margin: 0 5px;
                }
              }
            }
          }
        }
        &::after {
          clear: both;
          display: block;
          content: "";
        }
      }
    }
  }
}
.gmBut {
  height: 50px;
  background: #173d7c;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  line-height: 50px;
}
.lh50 {
  line-height: 50px;
  font-size: 16px;
  font-weight: bold;
  color: #ff4600;
  span {
    height: 16px;
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    margin-left: 15px;
    font-weight: normal;
  }
}
.font-1 {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 28px;
}
.font-time {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
}
.lh50bf {
  line-height: 50px;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  input {
    float: left;
    margin-top: 20px;
    margin-right: 10px;
  }
}
.borC {
  border-top-left-radius: 0 !important;
}
.tasBut {
  float: left;
  span {
    cursor: pointer;
    width: 24px;
    height: 24px;
    float: left;
    line-height: 24px;
    text-align: center;
    margin-top: 13px;
  }
}
</style>

<style>
.itemSpan p,
.inWor p {
  display: inline;
  white-space: pre-line;
}
.SorDdTitle .el-radio__input,
.SorDdTitle .el-checkbox__input {
  float: left;
  margin-top: 2px;
}
.SorDdTitle .el-radio__label,
.SorDdTitle .el-checkbox__label {
  display: block;
  padding-left: 20px;
  line-height: 20px;
}
</style>
